import { Component, OnInit, ViewChild, Input, NgZone } from '@angular/core';
import { MarkerService } from 'src/app/services/marker.service';
import { AgmMap, AgmInfoWindow, AgmMarker } from '@agm/core';
import { Router } from '@angular/router';
import { ElementRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MarkerModel, Pos } from 'src/app/models/marker.model';

declare var $: any;

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    geoCoder: google.maps.Geocoder;
    firstResult: any;

    constructor(private router: Router, public markersService: MarkerService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone
    ) {

        this.mapsAPILoader.load().then(() => {
            this.autocompleteService = new google.maps.places.AutocompleteService();
        });
    }

    markers: MarkerModel[];
    cleanListMarkers: MarkerModel[];
    lat;
    lng;
    zoom;
    address: string;
    delMarkerBtn: boolean = false;
    private autocompleteService;
    searchInput;

    @ViewChild('search')

    public searchElementRef: ElementRef;
    private singleInfoWindow: AgmInfoWindow = null;
    //zakharivska 48
    styles = [
        {
            'featureType': 'administrative',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f27d7d'
                },
                {
                    'weight': 6.5
                }
            ]
        },
        {
            'featureType': 'administrative',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'administrative.locality',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'administrative.province',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'landscape',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#cccccc'
                },
                {
                    'lightness': -55
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#e52424'
                }
            ]
        },
        {
            'featureType': 'landscape.natural',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#707070'
                }
            ]
        },
        {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ababab'
                }
            ]
        },
        {
            'featureType': 'poi',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'poi.sports_complex',
            'stylers': [
                {
                    'color': '#e52424'
                },
                {
                    'lightness': 100
                },
                {
                    'visibility': 'on'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#757575'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#757575'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'labels',
            'stylers': [
                {
                    'color': '#fafafa'
                },
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#e6e6e6'
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'color': '#fafafa'
                },
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#504965'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        }
    ];

    ngOnInit(): void {
        this.setDefaultPosition();

        this.markersService.getItemsList().subscribe(
            (items) => {
                this.cleanListMarkers = items;
                this.markers = Object.assign([], this.cleanListMarkers);
            });

        ///
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder;
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    this.lat = place.geometry.location.lat();
                    this.lng = place.geometry.location.lng();
                    this.zoom = 16;
                    const marker = new MarkerModel();
                    marker.pos = new Pos();
                    marker.pos.lat = place.geometry.location.lat();
                    marker.pos.lng = place.geometry.location.lng();
                    this.markers.push(marker);
                    this.delMarkerBtn = true;
                
            });
        });
        ///
    }

    showSelector() {
        $('.pac-container').css('display', 'block');
        $('.pac-container').css('visibility','visible');
    }

    setPlace() {

        let firstResult = $('.pac-container .pac-item:first').text();
        

        console.log(firstResult);

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': firstResult }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                $('.pac-container .pac-item:first').addClass('pac-selected');
                // $('.pac-container').css('display', 'none');
                // $('.pac-container').css('visibility', 'hidden');

                this.setPlaceInternal(results[0].geometry.location.lat(), results[0].geometry.location.lng(), firstResult);
            }
        });
    }

    setPlaceInternal(lat, lng, place) {


        this.ngZone.run(() => {
            this.lat = lat;
            this.lng = lng;
            this.zoom = 16;
            const marker = new MarkerModel();
            marker.pos = new Pos();
            marker.pos.lat = this.lat;
            marker.pos.lng = this.lng;
            this.markers = [...this.markers, marker];
            this.searchInput  = place;
            this.delMarkerBtn = true;
        })
    }

    // getPlacePredictions(term: string): Observable<any[]> {
    //     return Observable.create(observer => {
    //         // API Call

    //         this.autocompleteService.getPlacePredictions({ input: term }, data => {
    //             let previousData: Array<any[]>;

    //             // Data validation

    //             if (data) {
    //                 console.log(data);
    //                 previousData = data;
    //                 observer.next(data);
    //                 observer.complete();
    //             }

    //             // If no data, emit previous data

    //             if (!data) {
    //                 console.log('PreviousData: ');
    //                 observer.next(previousData);
    //                 observer.complete();

    //                 // Error Handling

    //             } else {
    //                 observer.error(status);
    //             }

    //         });

    //     });

    // }

    resumeMap() {
        this.setDefaultPosition();
        this.removeCustomMarker();
        this.delMarkerBtn = false;
    }

    setDefaultPosition() {
        this.lat = 50.4474755;
        this.lng = 30.5635115;
        this.zoom = 11.4;
    }

    removeCustomMarker() {
        this.markers = Object.assign([], this.cleanListMarkers);
    }


    trackByIdx(index: number, obj: any): any {
        return index;
    }

    showMap() {
        $('#p_prldr').css('display', 'none');
    }

    pause() {
        this.router.navigate(['/pause?timeout=true']);
    }

    showFrame(rtspAddr) {
        const frame = this.selectFrame();
        frame.css('display', 'unset');

        const frameblock = frame.parent();
        frameblock.css('display', 'unset');

        const btn = frameblock.find('.framebtn');
        btn.css('display', 'unset');

        if (rtspAddr) {
            const indexOf = rtspAddr.indexOf('youtube');

            if (rtspAddr && indexOf === -1) {
                frame.attr('src', 'https://rtsp.me/embed/' + rtspAddr);
            } else {
                frame.attr('src', rtspAddr);
            }
        }
    }

    markerClicked(infoWindow: AgmInfoWindow) {

        if (infoWindow.isOpen) {
            infoWindow.isOpen = false;
            infoWindow.close();
        } else {
            infoWindow.isOpen = true;
            infoWindow.open();
        }
    }

    selectFrame() {
        let frame;
        if ($('#cam_1').css('display') === 'none') {
            frame = $('#cam_1');
        } else if ($('#cam_2').css('display') === 'none') {
            frame = $('#cam_2');
        } else if ($('#cam_3').css('display') === 'none') {
            frame = $('#cam_3');
        } else if ($('#cam_4').css('display') === 'none') {
            frame = $('#cam_4');
        } else {
            frame = $('#cam_1');
        }

        return frame;
    }

    hideframe(camNumber) {
        $('#cam_' + camNumber).attr('src', '');
        $('#cam_' + camNumber).css('display', 'none');
        $('#framebtn_' + camNumber).css('display', 'none');
        $('#frameblock_' + camNumber).css('display', 'none');
    }

    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.lat, this.lng);
            });
        }
    }



    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    }
}