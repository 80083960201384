import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { NgModule } from '@angular/core';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { LoginComponent } from './components/login/login.component';
import { MapComponent } from './components/map/map.component';
import { EditMarkerComponent } from './components/admin/edit-marker/edit-marker.component';
import { CreateMarkerComponent } from './components/admin/create-marker/create-marker.component';
import { PauseComponent } from './components/pause/pause.component';

import { UserResolver } from './common/user.resolver';
import { AuthGuard } from './common/auth.guard';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { MarkerService } from './services/marker.service';


import { environment } from '../environments/environment';
import * as $ from 'jquery';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    MapComponent,
    EditMarkerComponent,
    CreateMarkerComponent,
    PauseComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAj0wLe_Ljt9IosDII8z1wJ4INHghrMMtc',
      libraries: ['places']
    }), BrowserAnimationsModule, MatGoogleMapsAutocompleteModule
  ],
  providers: [AuthService, { provide: LocationStrategy, useClass: HashLocationStrategy },
    UserService, UserResolver, AuthGuard, MarkerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
