import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { MarkerModel } from '../models/marker.model';
import { Observable } from 'rxjs';

@Injectable()
export class MarkerService {

    constructor(private db: AngularFireDatabase) { }

    public getItemsList() {
        const items = this.db.list<MarkerModel>(`markers`).valueChanges();
        return items;
    }

    public createMarker(marker: MarkerModel) {
        return this.db.object(`markers/${marker.id}`).set(marker);
    }

    public updateMarker(marker: MarkerModel) {
        return this.db.object(`markers/${marker.id}`).update(marker);
    }

    public deleteMarker(id) {
        return this.db.object(`markers/${id}`).remove();
    }
}
