import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
    selector: 'pause',
    templateUrl: './pause.component.html',
    styleUrls: ['./pause.component.scss']
})
export class PauseComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        const withoutTimeout = this.route.snapshot.paramMap.get('timeout');
        const preloader = $('#p_prldr');
        const svganm = preloader.find('.svg_anm');
        svganm.fadeOut();

        if (withoutTimeout) {
            debugger;
            return;
        }

        preloader.delay(2000).fadeOut('slow');

        setTimeout(() => {
            this.goToMap();
        }, 2000);
    }

    goToMap() {
        this.router.navigate(['/map']);
    }
}
