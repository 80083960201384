export class MarkerModel {

    constructor() {
        this.pos = new Pos();
        this.pos.lat = 0;
        this.pos.lng = 0;
    }

    id: number;
    name: string;
    pos: Pos;
    image: any;
    cameras: string[] = [];
}

export class Pos {
    lat: number;
    lng: number;
}