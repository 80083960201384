<div class="wrapper">
  <div class="window">
    <div class="title">Вход</div>
    <form [formGroup]="loginForm">
    <div class="form-group">
      <label>Электронный адрес</label>
      <input type="email" formControlName="email" class="form-control">
    </div>
    <div class="form-group">
      <label>Пароль</label>
      <input type="password" class="form-control" formControlName="password">
    </div>
    <div class="error-container"><label class="error">{{errorMessage}}</label></div> 
    <div class="btn-container"><button type="submit" (click)="tryLogin(loginForm.value)" class="btn btn-default">Войти</button></div>
  </form>
  </div>
</div>




<!-- <div class="wrapper">
  <h1 class="header">Вход</h1>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
        <form [formGroup]="loginForm">
          <div class="form-group">
            <label>Электронный адрес</label>
            <input type="email" formControlName="email" class="form-control">
          </div>
          <div class="form-group">
            <label>Пароль</label>
            <input type="password" class="form-control" formControlName="password">
          </div>
          <div class="error">
            <label class="error">Пользователь не найден или пароль введен неправильно</label>
          </div>
          <button type="submit" (click)="tryLogin(loginForm.value)" class="btn btn-default">Войти</button>
        </form>
      </div>
    </div>
  </div>
</div> -->