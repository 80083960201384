import { Component, OnInit } from '@angular/core';
import { MarkerService } from '../../services/marker.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MarkerModel } from 'src/app/models/marker.model';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private router: Router, public markersService: MarkerService) {

  }

  markers: MarkerModel[];

  ngOnInit(): void {
    this.markersService.getItemsList().subscribe(
      (items) => {
        this.markers = items;
      });
  }

  createMarker() {
    this.router.navigate(['/create']);
  }

  editMarker(id) {
    this.router.navigate([`/edit/${id}`]);
  }

  deleteMarker(id) {
    this.markersService.deleteMarker(id).then(() => {
      const index = this.markers.findIndex(i => i.id === id);
    });
  }
}
