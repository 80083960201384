import { Component, OnInit } from '@angular/core';
import { MarkerService } from '../../../services/marker.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/';

import { finalize } from 'rxjs/operators';
import { MarkerModel } from 'src/app/models/marker.model';

declare var $: any;

@Component({
    selector: 'edit-marker',
    templateUrl: './edit-marker.component.html',
    styleUrls: ['./edit-marker.component.scss']
})
export class EditMarkerComponent implements OnInit {

    private basePath = '/images';
    error: any;

    constructor(private router: Router, private route: ActivatedRoute,
                public markersService: MarkerService, public storage: AngularFireStorage) {

    }

    markerid;
    marker: MarkerModel = new MarkerModel();

    uploadPercent: Observable<number>;
    downloadURL: Observable<string>;

    ngOnInit(): void {
        this.markerid = this.route.snapshot.paramMap.get('id');
        this.markersService.getItemsList().subscribe(
            (items) => {
                // tslint:disable-next-line: radix
                this.marker = items.find(item => item.id === parseInt(this.markerid));
            });
    }

    trackByIdx(index: number, obj: any): any {
        return index;
    }

    addCamera() {
        this.marker.cameras.push('new');
    }

    deleteCamera(index) {
        this.marker.cameras.splice(index, 1);
    }

    addImage() {
        $('.image-upload-input-conainer').css('display', 'block');
    }

    validation() {
        this.error = undefined;

        if (!this.marker.name) {
            this.error = 'Введите название объекта';
        }

        if (!this.marker.pos.lat || this.marker.pos.lat === 0) {
            this.error = 'Введите широту объекта на карте';
        }

        if (!this.marker.pos.lng || this.marker.pos.lng === 0) {
            this.error = 'Введите долготу объекта на карте';
        }

        if (!this.marker.image) {
            this.error = 'Загрузите изображение объекта';
        }

        return !this.error;
    }

    updateMarker() {

        if (!this.validation()) {
            return;
        }

        this.markersService.updateMarker(this.marker).then(() => {
            this.returnToAdminPAge();
        });
    }

    returnToAdminPAge() {
        this.router.navigate(['admin']);
    }

    uploadFile(event) {
        const file = event.target.files[0];
        const filePath = `${this.basePath}/${file.name}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        task.snapshotChanges().pipe(
            finalize(() => {
                fileRef.getDownloadURL().subscribe((url) => {
                    this.marker.image = url;
                });

            })
        ).subscribe();
    }
}
