<div class="wrapper">
    <div class="window">
        <div class="title">Редактирование</div>
        <!-- <div class="btn-create-container">
            <div class="btn-container"><button type="submit" class="btn-create">Добавить</button></div>
        </div> -->
        <div class="marker-container">
            <label>Название объекта</label>
            <input [(ngModel)]="marker.name" />
            <label>Широта</label>
            <input [(ngModel)]="marker.pos.lat" />
            <label>Долгота</label>
            <input [(ngModel)]="marker.pos.lng" />
        </div>
        <div class="separator"></div> 
        <div class="cameras">
            <label>Камеры объекта</label>
            <div class="camera" *ngFor="let x of marker.cameras; let i = index;trackBy:trackByIdx">
                <input type="text" [(ngModel)]="marker.cameras[i]">
                <span class="btn-del" (click)="deleteCamera(i)" title="Удалить">x</span>
            </div>
            <div class="add-btn-container">
                <button type="submit" class="btn-create" (click)="addCamera()">Добавить камеру</button>
            </div>            
        </div>
        <div class="separator"></div> 
        <div class="image-upload-conainer">
            <label>Иконка объекта</label>
            <div class="btn-create-container">
                <button type="submit" class="btn-create" (click)="addImage()">Загрузить</button></div>
            <div class="image-upload-input-conainer">
                <input class="fileUploader" type="file" (change)="uploadFile($event)" />
            </div>
            <div class="image-container">
                <img [src]="marker.image" />
            </div>
        </div>  
        <div class="separator"></div> 
        <div class="btn-save-container">
            <button type="submit" class="btn-create" (click)="returnToAdminPAge()">Отмена</button>
            <button type="submit" class="btn-save" (click)="updateMarker()">Сохранить</button>
        </div> 
    </div>
</div>