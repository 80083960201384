import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/common/auth.guard';
import { UserResolver } from '../app/common/user.resolver';
import { AppComponent } from './app.component';
import { LoginComponent } from '../app/components/login/login.component';
import { AdminComponent } from '../app/components/admin/admin.component';
import { MapComponent } from './components/map/map.component';
import { EditMarkerComponent } from './components/admin/edit-marker/edit-marker.component';
import { CreateMarkerComponent } from './components/admin/create-marker/create-marker.component';
import { PauseComponent } from './components/pause/pause.component';

const routes: Routes = [
  { path: '', redirectTo: '/pause', pathMatch: 'full' },
  { path: 'pause', component: PauseComponent },
  { path: 'pause/:timeout', component: PauseComponent },
  { path: 'map', component: MapComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent,  resolve: { data: UserResolver} },
  { path: 'edit/:id', component: EditMarkerComponent,  resolve: { data: UserResolver} },
  { path: 'create', component: CreateMarkerComponent,  resolve: { data: UserResolver} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
