<div class="wrapper">
    <div class="logobox">
        <a class="logoboxlink" href="https://intergal-bud.com.ua/ru" target="_blank"></a>
    </div>
    <div class="serch-box">
        <input type="text" class="form-control" (keydown.enter)="setPlace()"
            placeholder="Поиск" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [(ngModel)]="searchInput">
        <span *ngIf="delMarkerBtn" (click)="search.value = ''; resumeMap();" (change)="showSelector()">x</span>
    </div>
    <div class="map-block" id="map">
        <agm-map #gm name="gm" [zoom]="zoom" [styles]="styles" [latitude]="lat" [longitude]="lng"
            [disableDoubleClickZoom]="true" [showDefaultInfoWindow]="false">
            <agm-marker *ngFor="let marker of markers; let ind = index;" [iconUrl]="marker.image"
                [latitude]="marker.pos.lat" [longitude]="marker.pos.lng" [openInfoWindow]="false"
                (markerClick)="markerClicked(infoWindow)">
                <agm-info-window #infoWindow id="{{marker.id}}" [disableAutoPan]="true">
                    <div class="context" id="content">{{marker.name}}</div>
                    <ul class="camlist">
                        <li *ngFor="let cam of marker.cameras;let i = index">
                            <a (click)="showFrame(cam);" class="camlink">Камера {{i + 1}}</a>
                        </li>
                    </ul>
                </agm-info-window>

            </agm-marker>
        </agm-map>
    </div>

    <!-- <google-map class="map-block" id="map" 
            height="600px" 
            width="100%" 
            [zoom]="11.4"
            [center]=""></google-map> -->
    <div id="frameblock_4" class="frameblock firstframe">
        <div id="framebtn_4" title="Закрыть окно" class="framebtn" (click)="hideframe(4)">X</div>
        <iframe id="cam_4" class="cam" name="frame_name" allowfullscreen></iframe>
    </div>
    <div id="frameblock_1" class="frameblock secondframe">
        <div id="framebtn_1" title="Закрыть окно" class="framebtn" (click)="hideframe(1)">X</div>
        <iframe id="cam_1" class="cam" name="frame_name" allowfullscreen></iframe>
    </div>
    <div id="frameblock_2" class="frameblock thirdframe">
        <div id="framebtn_2" title="Закрыть окно" class="framebtn" (click)="hideframe(2)">X</div>
        <iframe id="cam_2" class="cam" name="frame_name" allowfullscreen></iframe>
    </div>
    <div id="frameblock_3" class="frameblock fourthframe">
        <div id="framebtn_3" title="Закрыть окно" class="framebtn" (click)="hideframe(3)">X</div>
        <iframe id="cam_3" class="cam" name="frame_name" allowfullscreen></iframe>
    </div>
    <div class="map-panel">
        <ul class="map-panel__list">
            <form>
                <li>
                    <select name="gklist" id="mymenu" onchange="window.open(this.value)">
                        <option selected value="#">ЖК</option>
                        <option value="https://akadem-kvartal.com.ua">Академ-Квартал</option>
                        <option value="https://jk-skyavenue.com.ua">Sky Avenue</option>
                        <option value="https://nivki-park.com.ua">Нивки-Парк</option>
                        <option value="https://city-hub.com.ua">City Hub</option>
                        <option value="https://syretski-sady.com.ua">«Сирецькі Сади»</option>
                        <option value="https://ozernyi-gatne.com.ua">«Озерний Гай Гатне»</option>
                        <option value="https://teremki-bud.com.ua">Теремки</option>
                        <option value="https://prychal-8.com.ua">Причал 8</option>
                        <option value="https://intergal.city">Intergal City</option>
                    </select>
                </li>
                <li>
                    <select name="cammenu" #cammenu id="mycam" (change)="showFrame(cammenu.value)">
                        <option selected value="#">Камеры</option>
                        <option value="NBzk2y7G">Академ-Квартал 1</option>
                        <option value="es756N6H">Академ-Квартал 2</option>
                        <option value="NfZt8NfZ">Sky Avenue 1</option>
                        <option value="s3ft79Dh">Sky Avenue 2</option>
                        <option value="d4AdG9fi">Нивки-Парк 1</option>
                        <option value="4038REWZ">Нивки-Парк 2</option>
                        <option value="Sa9kTat8">City Hub 1</option>
                        <option value="kE8d44H2">City Hub 2</option>
                        <option value="U1MSIGpV">«Сирецькі Сади» 1</option>
                        <option value="NYTfQbfZ">«Сирецькі Сади» 2</option>
                        <option value="eQFsYfeQ">«Озерний Гай Гатне» 1</option>
                        <option value="xGszih3k">«Озерний Гай Гатне» 2</option>
                        <option value="EzbK27Zy">Теремки 1</option>
                        <option value="EzbK27Zy">Теремки 2</option>
                        <option value="FkS297eB">Причал 1</option>
                        <option value="kuZDoO7iIHU">Причал 2</option>
                        <option value="r9nr5bb6">Intergal City 1</option>
                    </select>
                </li>
                <li><a [routerLink]="['/pause', true]" class="map-panel__link">Закрыть</a></li>
            </form>
        </ul>
    </div>
</div>