<div class="wrapper">
    <div class="window">
        <div class="title">Управление объектами</div>
        <div class="btn-create-container">
            <div class="btn-container"><button type="submit" (click)="createMarker()" class="btn-create">Добавить</button></div>
        </div>
        <div class="marker-list">
            <div *ngFor="let marker of markers; let i = index;" class="marker-container">
                <div class="marker-details name">{{marker.name}}</div>
                <div class="marker-btns">
                    <div class="marker-details btn-container"><a (click)="editMarker(marker.id)">Редактировать</a></div>
                    <div class="marker-details btn-container"><a (click)="deleteMarker(marker.id)">Удалить</a></div>
                </div>
            </div>
        </div>
    </div>
</div>